import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiDeleteBinLine } from "react-icons/ri";
import dayjs from 'dayjs';

function Messages() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        axios
            .get(`/api/Request`)
            .then((response) => {
                // Daten absteigend nach Datum sortieren
                const sortedData = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setData(sortedData);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = (id) => {
        setLoading(true);
        axios
            .delete(`api/Request/${id}`)
            .then(() => {
                fetchData(); // Daten nach Löschen erneut laden
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <>
            <div className="rounded-4 py-3 my-3 bg-white shadow-lg">
                <div className='justify-content-center'>
                    <div className='fw-semibold rakete-font text-danger fs-5 ms-3 text-center text-uppercase py-2'>Anfragen</div>
                    <div className='fs-7'>
                        <div className="">
                            {loading ? (
                                <div className='rakete-font fs-7 ms-3 text-start text-secondary ps-2 py-2 text-center'>Laden...</div>
                            ) : data && data.length !== 0 ? (
                                data.map((message) => (
                                    <div key={message._id} className="rounded-5 row py-2 align-items-center events justify-content-center mx-2">
                                        <div className='row d-flex justify-content-center align-items-center my-3'>
                                            <div className='col'>
                                                <div className='row align-items-center'>
                                                    <div className='col-auto fs-8 fw-semibold rakete-font'>
                                                        {dayjs(message.createdAt).format("DD.MM.YY HH:mm")}
                                                    </div>
                                                    <div className='col-auto fs-8 fw-semibold rakete-font text-uppercase'>
                                                        {message.topic == 0
                                                            ? "Kontakt"
                                                            : message.topic == 1
                                                            ? "Mitglied"
                                                            : message.topic == 2
                                                            ? "Sponsoring"
                                                            : "Turnier"}
                                                    </div>
                                                    <div className='col-md-6 fw-semibold text-wrap'>
                                                        {message.firstname} {message.lastname}{" "}
                                                        {message.topic == 0
                                                            ? "hat eine Frage"
                                                            : message.topic == 1
                                                            ? "möchte Rakete beitreten"
                                                            : message.topic == 2
                                                            ? "möchte uns unterstützen"
                                                            : "hat ein Team angemeldet"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <RiDeleteBinLine
                                                    size={20}
                                                    className='hover icons mx-1 my-1'
                                                    onClick={() => handleDelete(message._id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='rakete-font fs-7 ms-3 text-start text-secondary ps-2 py-2 text-center'>Keine neuen Anfragen</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Messages;
